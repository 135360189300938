<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>My Account</h2>
    <div class="uk-card uk-card-default uk-card-body">
      <ul class="uk-tab">
        <li
          :class="$route.name == 'affiliate.my-account' ? 'uk-active' : ''"
          :aria-expanded="$route.name == 'affiliate.my-account'"
        >
          <router-link :to="{ name: 'affiliate.my-account' }">Security</router-link>
        </li>
      </ul>

      <ul id="my-profile-content" class="uk-switcher uk-margin">
        <li class="uk-active" aria-hidden="false">
          <router-view />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>
